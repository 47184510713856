<template>
    <div class="games pb-4 pt-4 lg:pb-0 lg:pt-0">
        <!-- all page's children will inhert parents layout -->
        <div class="container my-0 flex h-full w-full lg:px-4">
            <div class="flex w-full flex-col lg:pt-7">
                <div class="w-full lg:pb-14">
                    <NuxtPage />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup></script>
